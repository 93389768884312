<template>
  <section
    id="overview"
  >
    <core-jumbotron-mobile />
    <heading class="text-capitalize">
      {{ $t("terms.legal") }}
    </heading>

    <v-container>
      <v-row
        align="center"
        class="mb-5"
      >
        <v-col
          cols="12"
        >
          <h1
            align="center"
          >
            <span
              lang="FR"
            >Mentions légales</span>
          </h1>

          <p

            align="center"
          >
            <i><span
              lang="FR"
            >En
              vigueur au 01/01/2020</span></i>
          </p>

          <p

            align="center"
          >
            <span
              lang="FR"
            >&nbsp;</span>
          </p>

          <p>
            <span
              lang="FR"
            >Conformément aux
              dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour
              la Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la
              connaissance des Utilisateurs du site www.oriaagriculture.com les
              présentes mentions légales.</span>
          </p>

          <p>
            <span
              lang="FR"
            >La connexion et
              la navigation sur le site www.oriaagriculture.com par l’Utilisateur
              implique acceptation intégrale et sans réserve des présentes mentions légales.</span>
          </p>

          <p>
            <span
              lang="FR"
            >Ces dernières
              sont accessibles sur le site à la rubrique « Mentions légales ».</span>
          </p>

          <h1>
            <span
              lang="FR"
            >ARTICLE 1
              : L’éditeur</span>
          </h1>

          <p>
            <span
              lang="FR"
            >L’édition et la
              direction de la publication du site www.oriaagriculture.com est assurée
              par l'entreprise WRIGHT Matthew, <a
                target="_blank"
                href="https://matwright.dev"
              >développeur d'applications web & mobile</a>,  domiciliée 01340 MARSONNAS, dont le numéro de téléphone est
              0474329373, et l'adresse e-mail <a class="SpellE"><a href="mail@matwright.dev">mail@matwright.dev</a>.</a></span>
          </p>

          <p>
            <span
              lang="FR"
            >&nbsp;</span>
          </p>

          <p>
            <span
              lang="FR"
            >La personne est
              assujettie au RCS avec le numéro d'inscription Bourg-En-Bresse A 483 770 129 et
              le numéro de TVA FR 0J483770129</span>
          </p>

          <h1>
            <span
              lang="FR"
            >ARTICLE 2
              : L’hébergeur</span>
          </h1>

          <p>
            <span
              lang="FR"
            >L'hébergeur du
              site www.oriaagriculture.com est la Société Google, dont le siège
              social est situé au 1600 <span class="SpellE">Amphitheatre</span> Parkway, <span
                class="SpellE"
              >Mountain</span> <span class="SpellE">View</span>, California 94043,
              United States.</span>
          </p>

          <h1>
            <span
              lang="FR"
            >ARTICLE 3
              : Accès au site</span>
          </h1>

          <p>
            <span
              lang="FR"
            >Le site est
              accessible par tout endroit, 7j/7, 24h/24 sauf cas de force majeure,
              interruption programmée ou non et pouvant découlant d’une nécessité de
              maintenance.</span>
          </p>

          <p>
            <span
              lang="FR"
            >En cas de
              modification, interruption ou suspension des services le site <i>www.oriaagriculture.com&nbsp;</i>ne
              saurait être tenu responsable.</span>
          </p>

          <h1>
            <span
              lang="FR"
            >ARTICLE 4
              : Collecte des données</span>
          </h1>

          <p>
            <span
              lang="FR"
            >Le site est
              exempté de déclaration à la Commission Nationale Informatique et Libertés
              (CNIL) dans la mesure où il ne collecte aucune donnée concernant les
              utilisateurs.</span>
          </p>

          <h1>
            <span
              lang="FR"
            >ARTICLE 5
              : Cookies</span>
          </h1>

          <p

            style="margin-top:10.0pt;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes"
          >
            <span
              lang="FR"
            >L’Utilisateur est
              informé que lors de ses visites sur le site, un cookie peut s’installer
              automatiquement sur son logiciel de navigation.</span>
          </p>

          <p>
            <span
              lang="FR"
            >En naviguant sur
              le site, il les accepte.</span>
          </p>

          <p>
            <span
              lang="FR"
            >Un cookie est un
              élément qui ne permet pas d’identifier l’Utilisateur mais sert à enregistrer
              des informations relatives à la navigation de celui-ci sur le site Internet.
              L’Utilisateur pourra désactiver ce cookie par l’intermédiaire des paramètres
              figurant au sein de son logiciel de navigation.</span>
          </p>

          <p>
            <span
              lang="FR"
            >&nbsp;</span>
          </p>

          <h1>
            <span
              lang="FR"
            >ARTICLE 6
              : Propriété intellectuelle</span>
          </h1>

          <p>
            <span
              lang="FR"
            >Toute
              utilisation, reproduction, diffusion, commercialisation, modification de toute
              ou partie du site <i>www.oriaagriculture.com</i>,
              sans autorisation de l’Editeur est prohibée et pourra entraînée des actions et
              poursuites judiciaires telles que notamment prévues par le Code de la propriété
              intellectuelle et le Code civil.</span>
          </p>

          <p>
            <span
              lang="FR"
            >Pour plus
              d’informations, se reporter aux&nbsp;<i>CGV&nbsp;</i>du
              site www.oriaagriculture.com accessible à la rubrique<i>« CGV »&nbsp;</i></span>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  import i18n from '../i18n'

  export default {
    metaInfo: {
      title: i18n.t('menu.about_us'),
      meta: [
        { name: 'description', content: 'Oria Agriculture' },
      ],
    },

    components: {
      Heading: () => import('@/components/Heading'),

      CoreJumbotronMobile: () => import('@/components/core/JumbotronMobile'),
    },
  }
</script>
